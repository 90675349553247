/*******************************************************************

	Modal Invoice Report

*******************************************************************/

function ModalInvoiceReport() {
    this.$modal = $('.invoices_action_modal.invoices_action_modal--report');
    this.$btnPrimary = this.$modal.find('.modal-footer .btn--action');
    this.$btnSecondary = this.$modal.find('.modal-footer .btn--cancel');
    this.table = new ModalInlineTable(this.$modal);
    this.feedback = new ModalFeedback();
    this.data = [];
    this.url = undefined;
	this.xhr = undefined;
}

ModalInvoiceReport.prototype.init = function(url) {
    this.data = [];
    this.url = url;

    this.$btnPrimary.attr('disabled', true);
    this.$btnPrimary.bind('click', $.proxy(this.onPrimary, this));
    this.$btnSecondary.bind('click', $.proxy(this.onSecondary, this));

    this.$modal.modal({
        backdrop: 'static'
    });
    this.loadInitData();
};

ModalInvoiceReport.prototype.destroy = function() {
    this.$btnPrimary.unbind('click', this.onPrimary);
    this.$btnSecondary.unbind('click', this.onSecondary);
    this.$btnPrimary.attr('disabled', true);
    this.$modal.find('textarea[name=comment]').val('');
    this.table.clear();
    this.data = [];
    this.url = undefined;

    this.$modal.modal('hide');
};

ModalInvoiceReport.prototype.loadInitData = function() {
    this.table.clear();

    $.getJSON(this.url, $.proxy(function(data) {
        this.table.init();

        this.data = data.data;
        this.table.addData(this.data);
        this.$btnPrimary.attr('disabled', false);
    }, this));
};

ModalInvoiceReport.prototype.ajaxStart = function() {
    this.$btnPrimary.append('<i class="loader"></i>');
    this.$btnPrimary.attr('disabled', true);

    this.data.comment = this.$modal.find('textarea[name=comment]').val();
    var dataClone = jQuery.extend(true, {}, this.data);

    this.xhr = $.ajax({
        method: 'POST',
        url: this.$modal.data('action'),//'/login/mailings/fehler-melden/',
        cache: false,
        data: dataClone,
    }).done($.proxy(this.ajaxComplete, this));
};

ModalInvoiceReport.prototype.ajaxComplete = function(feedback) {

    this.$btnPrimary.find('.loader').remove();
    this.$btnPrimary.attr('disabled', false);

    this.destroy();
    this.feedback.init(feedback);
};

ModalInvoiceReport.prototype.onPrimary = function(e) {
    if(!this.$btnPrimary.attr('disabled')) {
        this.ajaxStart();
    }

    e.preventDefault();
};

ModalInvoiceReport.prototype.onSecondary = function(e) {
    this.destroy();

    e.preventDefault();
};
