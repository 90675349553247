/*******************************************************************

	Modal Feedback

*******************************************************************/

function ModalFeedback() {
    this.$modal = $('.invoices_upload__modal--feedback');
    this.$btnPrimary = this.$modal.find('.modal-footer .btn--action');
    this.$message = this.$modal.find('.modal-body p');
}

ModalFeedback.prototype.init = function(feedback) {
    var fb;

    if(typeof feedback === 	"string") {
        fb = JSON.parse(feedback);
    } else {
        fb = feedback;
    }
    
    this.$btnPrimary.bind('click', $.proxy(this.onPrimary, this));
    this.$message.text(fb.response);

    this.$modal.modal({
        backdrop: 'static'
    });

};

ModalFeedback.prototype.destroy = function() {
    this.$btnPrimary.unbind('click', this.onPrimary);
    this.$message.empty();
    this.$modal.modal('hide');
};

ModalFeedback.prototype.onPrimary = function(e) {
    this.destroy();
    e.preventDefault();
};
