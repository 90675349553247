/*******************************************************************


	Extending JS


*******************************************************************/


//Make Words Start With Big Letter
String.prototype.capitalize = function(lower) {
    return (lower ? this.toLowerCase() : this).replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
};

//Replace all strings in strings
String.prototype.replaceAll = function(search, replace) {
    //if replace is not sent, return original string otherwise it will
    //replace search string with 'undefined'.
    if (replace === undefined) {
        return this.toString();
    }

    return this.replace(new RegExp('[' + search + ']', 'g'), replace);
};

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}


/*******************************************************************


	Global Variable


*******************************************************************/

//Loading v11 invoices
var iLoadingPortCount = 4;
var iLoadingIndex;
var iLoadingTotal;
var iLoadingDone;
var iLoadingOK;
var iLoadingError;
var invoices;
var invoicesDetails;
var modalObject;

var tableUpload;
var tableInvoices;

var modalReport = new ModalInvoiceReport();
var modalRecord = new ModalInvoiceRecord();
var modalReminder = new ModalInvoiceReminder();
var modalResend = new ModalInvoiceResend();
var modalUploaded = new ModalInvoiceUploaded();



/*******************************************************************


	Internationalisation


*******************************************************************/

var languages = {};

languages.de = {
    //DataTable Translation
	"sEmptyTable":   	"Keine Daten in der Tabelle vorhanden",
	"sInfo":         	"_START_ bis _END_ von _TOTAL_ Einträgen",
	"sInfoEmpty":    	"0 bis 0 von 0 Einträgen",
	"sInfoFiltered": 	"(gefiltert von _MAX_ Einträgen)",
	"sInfoPostFix":  	"",
	"sInfoThousands":  	".",
	"sLengthMenu":   	"_MENU_ Einträge anzeigen",
	"sLoadingRecords": 	"Wird geladen...",
	"sProcessing":   	"Bitte warten...",
	"sSearch":       	"Suchen",
	"sZeroRecords":  	"Keine Einträge vorhanden.",
	"oPaginate": {
		"sFirst":    	"Erste",
		"sPrevious": 	"Zurück",
		"sNext":     	"Nächste",
		"sLast":     	"Letzte"
	},
	"oAria": {
		"sSortAscending":  ": aktivieren, um Spalte aufsteigend zu sortieren",
		"sSortDescending": ": aktivieren, um Spalte absteigend zu sortieren"
	},

    //System Translation
    "notFound":         "nicht gefunden",
    "amountNoMatch":    "Betrag unstimmig",
    "waiting":          "warte...",
    "error":            "fehler",
    "paid":             "bezahlt",
    "paidAlready":      "bereits verbucht",
    "open":             "offen",
    "pending":          "pendent",
    "verifying":        "prüfe",
    "emailnotvalid":    "Email ungültig"
};

languages.fr = {
    //DataTable Translation
	"sProcessing":     "Traitement en cours...",
	"sSearch":         "Rechercher&nbsp;:",
    "sLengthMenu":     "Afficher _MENU_ &eacute;l&eacute;ments",
	"sInfo":           "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
	"sInfoEmpty":      "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
	"sInfoFiltered":   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
	"sInfoPostFix":    "",
	"sLoadingRecords": "Chargement en cours...",
    "sZeroRecords":    "Aucun &eacute;l&eacute;ment &agrave; afficher",
	"sEmptyTable":     "Aucune donn&eacute;e disponible dans le tableau",
	"oPaginate": {
		"sFirst":      "Premier",
		"sPrevious":   "Pr&eacute;c&eacute;dent",
		"sNext":       "Suivant",
		"sLast":       "Dernier"
	},
	"oAria": {
		"sSortAscending":  ": activer pour trier la colonne par ordre croissant",
		"sSortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
	},

    //System Translation
    "notFound":         "nicht gefunden",
    "amountNoMatch":    "Betrag unstimmig",
    "waiting":          "warte...",
    "error":            "fehler",
    "paid":             "bezahlt",
    "paidAlready":      "bereits verbucht",
    "open":             "offen",
    "pending":          "pendent",
    "verifying":        "prüfe",
    "emailnotvalid":    "Email ungültig"
};

function lang() {
    return $('html').attr('lang');
}

function i18n(value) {
    return languages[lang()][value];
}

/*******************************************************************


	Table Behavoirs


*******************************************************************/

function initUploadTable() {
    tableUpload = $('.datatable-upload').DataTable({
        bPaginate: false,
        bFilter: true,
        bInfo: false,
        language: languages[lang()],
        footerCallback: function ( row, data, start, end, display ) {
            var api = this.api();

            // Remove the formatting to get integer data for summation
            var intVal = function ( i ) {
                return typeof i === 'string' ?
                    i.replace(/[\$,]/g, '')*1 :
                    typeof i === 'number' ?
                        i : 0;
            };

            // Total over all pages
            total = api
                .column( 6 )
                .data()
                .reduce( function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0 );


            // Update footer
            $( api.column( 6 ).footer() ).html(
                total.toFixed(2)
            );
        }
    });

    $('.invoices__upload__state__info--process .cancel').bind('click', function(e) {

        $('.invoices__upload__state__info--aborted').show();
        $('.invoices__upload__state__info--done').hide();
        $('.invoices__upload__state__info--process').hide();
        $('#loadingbar').removeAttr('style');
        abortInvoices();
        e.preventDefault();
    });

    $('.invoices__upload__state__info--done .transfer').bind('click', function(e) {

        $('.invoices__upload__state__info--aborted').hide();
        //$('.invoices__upload__state__info--done').show();
        $('.invoices__upload__state__info--process').hide();
        $('.invoices_upload__modal.invoices_upload__modal--success').modal();
        //initInvoicesTransmit();
        e.preventDefault();
    });

    $('.invoices__upload__state__info--aborted .restart').bind('click', function(e) {

        startCheckingInvoices();
        $('.invoices__upload__state__info--aborted').hide();
        $('.invoices__upload__state__info--done').hide();
        $('.invoices__upload__state__info--process').show();

        e.preventDefault();
    });

    startCheckingInvoices();

}


function initInvoicesTable() {

    //Init Table

    tableInvoices = $('.datatable-invoices').DataTable({
        bPaginate: false,
        bFilter: true,
        bInfo: false,
        language: languages[lang()],
        "columns": [
            { className: "state" },
            { className: "screen-l screen-m date" },
            { className: "screen-l screen-m txt" },
            { className: "screen-s screen-xs date_txt" },
            { className: "comment align-center" },
            { className: "screen-l currency" },
            { className: "screen-l amount align-right" },
            { className: "screen-m screen-s screen-xs currency_amount align-right" },
            { className: "actions align-right" }
        ],
        "createdRow": function( row, data, dataIndex ) {
            var dataObj = data[data.length-1];

            for (var k in dataObj.attr){
                $(row).attr(k, dataObj.attr[k]);
                $(row).attr('data-index', dataIndex);
            }

        },
        footerCallback: function ( row, data, start, end, display ) {
            var api = this.api();

            // Remove the formatting to get integer data for summation
            var intVal = function ( i ) {
                return typeof i === 'string' ?
                    i.replace(/[\$,]/g, '')*1 :
                    typeof i === 'number' ?
                        i : 0;
            };

            // Total over all pages
            total = api
                .column( 6 )
                .data()
                .reduce( function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0 );


            // Update footer
            $( api.column( 6 ).footer() ).html(
                total.toFixed(2)
            );

            $( api.column( 7 ).footer() ).html('CHF ' +
                total.toFixed(2)
            );
        }
    });

    $('.datatable-invoices').on('click', 'td .dropdown-menu li a', function(e) {
        var $tr = $(this).closest('tr');

        switch($(this).attr('href')) {
            case '#invoices_action_modal--record':
                modalRecord.init('/?datatype=json&datasource=invoice&invoice_number='+$tr.attr('data-post_id'));
                break;
            case '#invoices_action_modal--reminder':
                modalReminder.init('/?datatype=json&datasource=invoice&invoice_number='+$tr.attr('data-post_id'));
                break;
            case '#invoices_action_modal--resend':
                modalResend.init('/?datatype=json&datasource=invoice&invoice_number='+$tr.attr('data-post_id'));
                break;
            case '#invoices_action_modal--report':
                modalReport.init('/?datatype=json&datasource=invoice&invoice_number='+$tr.attr('data-post_id'));
                break;
        }

        e.preventDefault();
    });

    //Init User Interface

    $('#invoices__tabs a').click(function (e) {
        e.preventDefault();
        $(this).tab('show');

        updateInvoicesTableByTab($(this).attr('href'));
    });

    $('#invoces__timelaps a').click(function (e) {
        e.preventDefault();
        $(this).tab('show');

        $(this).parent().children().removeClass('loading');
        $(this).parent().addClass('loading');

        tableInvoices.ajax.url(this.href+'&datatype=json').load(function() {
            $('#invoces__timelaps li').removeClass('loading');
            initInvoicesTableInfos();
            updateTabBadgeInfo(true);
        });
    });

    $('#invoces__timelaps li.active a').trigger('click');

    $('.invoices__filter__search input').keyup(updateInvoicesTableBySearch);

    $('.invoices__filter__search .btn-close').click(function(e){
    	e.preventDefault();
    	$('.invoices__filter__search input').val('').trigger('keyup');
    });

    $('#invoices__upload__v11 input[type=file]').change(function() {
      $('#invoices__upload__v11').submit();
    });
}

function updateInvoicesTableBySearch(e) {
    if(!tableInvoices) {
        return;
    }

    $input = $('.invoices__filter__search input');

    if($input.val().length >= 3) {
        tableInvoices.search($input.val()).draw();
    } else {
        tableInvoices.search('').draw();
    }

    if($input.val().length >= 1) {
        $input.closest('.invoices__filter__search').addClass('invoices__filter__search--close');
    } else {
        $input.closest('.invoices__filter__search').removeClass('invoices__filter__search--close');
    }
}

function updateInvoicesTableByTab(str) {
    if(!tableInvoices) {
        console.log('tableInvoices not found');
        return;
    }

    switch(str) {
        case '#unpaid':
            tableInvoices.rows().every( function ( rowIdx, tableLoop, rowLoop ) {
                var data = this.data();

                if($(this.node()).attr('data-unpaid') == 1) {
                    $(this.node()).show();
                } else {
                    $(this.node()).hide();
                }
            });
            break;
        case '#reminded':
            tableInvoices.rows().every( function ( rowIdx, tableLoop, rowLoop ) {
                var data = this.data();

                if($(this.node()).attr('data-reminded') == 1) {
                    $(this.node()).show();
                } else {
                    $(this.node()).hide();
                }
            });
            break;
        case '#withcomments':
                tableInvoices.rows().every( function ( rowIdx, tableLoop, rowLoop ) {
                    var data = this.data();

                    if($(this.node()).find('.comment [data-type=info]').length !== 0) {
                        $(this.node()).show();
                    } else {
                        $(this.node()).hide();
                    }
                });
                break;
        default:
            tableInvoices.rows().every( function ( rowIdx, tableLoop, rowLoop ) {
                $(this.node()).show();
            });
            break;
    }

    var totalAmount = 0;
    tableInvoices.rows().every( function ( rowIdx, tableLoop, rowLoop ) {
        var amount = this.data();

        if($(this.node()).css('display') != 'none') {
            totalAmount += Number(amount[6]);
        }
    });

    $('#invoices__overview__table tfoot tr .amount').text(Math.round(totalAmount * 100) / 100);



}

function updateInvoicesTableByFilters() {
    updateInvoicesTableBySearch(null);
    updateInvoicesTableByTab($('#invoices__tabs li.active a').attr('href'));
}

function updateTabBadgeInfo(force) {
    $tabAll = $('#invoices__tabs li.all');
    $tabUnpaid = $('#invoices__tabs li.unpaid');
    $tabReminded = $('#invoices__tabs li.reminded');
    $tabComments = $('#invoices__tabs li.comments');

    if($tabAll.find('.badge').length === 0) {
        $tabAll.find('a').append('<span class="badge">0</span>');
    }

    if($tabUnpaid.find('.badge').length === 0) {
        $tabUnpaid.find('a').append('<span class="badge">0</span>');
    }

    if($tabComments.find('.badge').length === 0) {
        $tabComments.find('a').append('<span class="badge">0</span>');
    }

    if($tabReminded.find('.badge').length === 0) {
        $tabReminded.find('a').append('<span class="badge">0</span>');
    }

    $tabAll.find('.badge').text($('#invoices__overview__table tbody tr:not(:has(td.dataTables_empty))').length);
    $tabUnpaid.find('.badge').text($('#invoices__overview__table tbody tr[data-unpaid=1]').length);

    //Filter Table on changing number of entries on tabs
    var actualCommentsCount = Number($tabComments.find('.badge').text());
    var actualRemindedCount = Number($tabReminded.find('.badge').text());


    var rows;

    rows = $('#invoices__overview__table tbody .comment').filter(function() {
        return $(this).find('[data-type=info]').length > 0;
    });

    var newCommentsCount = rows.length;
    $tabComments.find('.badge').text(newCommentsCount);

    rows = $('#invoices__overview__table tbody .comment').filter(function() {
        return $(this).find('[data-type=reminder]').length > 0;
    });

    var newRemindedCount = rows.length;
    $tabReminded.find('.badge').text(newRemindedCount);


    if($tabComments.hasClass('active') && actualCommentsCount != newCommentsCount || $tabComments.hasClass('active') && force === true) {
        updateInvoicesTableByFilters();
    }

    if($tabReminded.hasClass('active') && actualRemindedCount != newRemindedCount || $tabReminded.hasClass('active') && force === true) {
        updateInvoicesTableByFilters();
    }
}


/*******************************************************************


	Init Application


*******************************************************************/


$(document).ready(function () {

    //Uploading v11
    if($('.datatable-upload').length > 0) {
        initUploadTable();
    }

    //Invoice Overview
    if($('.datatable-invoices').length > 0) {
        initInvoicesTable();
    }

    $('body').tooltip({
        selector: '[data-toggle="tooltip"]'
    });

    $('#record_invoice-date').datepicker({
        format: "yyyy-mm-dd"
    });

    var swiper = new Swiper ('.swiper-container', {
        // Navigation arrows
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
    });

    $('#yform-formular-contact_reason select option[value="'+$('#yform-formular-qry').val()+'"]').attr('selected', 'selected');

    $('.mailchimp-form form').bind('submit', function(e) {
        var val = $(this).find('#mailchimp-email').val();

        if(val === '' || validateEmail(val) === false) {
            alert(i18n('emailnotvalid'));
        } else {
            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                data: $(this).serialize(), // serializes the form's elements.
                success: function(data) {
                    $('.mailchimp-form').hide();

                    if(data === '200') {
                        $('#mailchimp-messages--success').show();
                    } else {
                       $('#mailchimp-messages--error').show();
                    }
                }
            });
        }

        return false;
    });
});
