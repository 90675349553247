/*******************************************************************


	InvoiceDetail Class


*******************************************************************/

//Helper Classes

function initInvoicesTableInfos() {
    invoicesDetails = [];

	$('#invoices__overview__table tbody tr:not(:has(td.dataTables_empty))').each(function() {
		invoicesDetails.push(new InvoiceDetails($(this), invoicesDetailsLoadedComplete));
	});

	iLoadingIndex = -1;
    iLoadingDone = 0;
    iLoadingOK = 0;
    iLoadingError = 0;
	iLoadingTotal = invoicesDetails.length;
    tmpLoadingPortCount = iLoadingPortCount;

    if(iLoadingTotal > 0) {

        $('.invoices__table__process .info__process__number').text('0/'+iLoadingTotal);
        $('.invoices__table__process').show();

        if(tmpLoadingPortCount > iLoadingTotal) {
            tmpLoadingPortCount = iLoadingTotal;
        }

        for(var i = 0; i < tmpLoadingPortCount; i++) {
            iLoadingIndex++;
            invoicesDetails[iLoadingIndex].start();
        }
    }
}


function abortInvoicesDetails() {
	for(var i = 0; i < invoicesDetails.length; i++) {
		invoicesDetails[i].abort();
	}

    $('.invoices__table__process .info__process__number').text('0/0');
    $('.invoices__table__process').hide();
}

function invoicesDetailsLoadedComplete() {
	iLoadingIndex++;

	if(iLoadingIndex < iLoadingTotal) {
		invoicesDetails[iLoadingIndex].start();
	} else {
        $('.invoices__table__process').hide();
    }

    updateTabBadgeInfo();
    $('.invoices__table__process .info__process__number').text(iLoadingIndex+'/'+iLoadingTotal);

    //updateInvoicesTableByFilters();
}

//Constructor

function InvoiceDetails($row, completeFunc) {
	this.$row = $row;
	this.xhr = undefined;
	this.receipt_number = $row.attr('data-receipt_number');
	this.post_id = $row.attr('data-post_id');
	this.complete = completeFunc;
	this.changeState('');
}


//Start loading Invoice Details
InvoiceDetails.prototype.start = function(data) {
	this.getInvoiceInfos(this.receipt_number);
	this.changeState('loading');
};

Invoice.prototype.abort = function() {
	if(this.xhr !== undefined) {
		this.xhr.abort();
	}
};


//Change State in Table
InvoiceDetails.prototype.changeState = function(state, txt) {
	switch (state) {
		case 'loading':
			this.$row.find('.comment').html('<i class="loader"></i>');
			break;
		case 'error':
			this.$row.find('.comment').html('<i class="glyphicon glyphicon-flash icon-danger"></i>');
			break;
        case 'comment':
            this.$row.find('.state .label').text(i18n('pending')).removeClass().addClass('label label-warning');
        	this.$row.find('.comment').html('<i data-toggle="tooltip" data-type="info" data-placement="top" class="glyphicon glyphicon-info-sign icon-info" title="'+txt+'"></i>');
            break;
		default:
			this.$row.find('.comment').empty();
			break;
	}
};


//Get invoice details voer invoice id
InvoiceDetails.prototype.getInvoiceInfosDetails = function(data) {

	if(data.data.receipt_comment !== undefined) {
        // this.$row.find('.name').text(data.data[0].Name.replaceAll('_', ' ').capitalize(true));
		// this.$row.find('.invoice_number').text(data.data[0].BelegNr);
        var receipt_comment = data.data.receipt_comment;
        receipt_comment = receipt_comment.replace('canceled: ', '').replace('frozen: ', '');
		this.changeState('comment', receipt_comment);
        this.$row.find('.action-resend').show();
        this.$row.find('.action-remind').hide();
	} else if(data.data.length === 0) {
		this.changeState();
	} else {
		this.changeState('error');
	}

	this.complete();
};

//Get Invoice Details over BelegNr
InvoiceDetails.prototype.getInvoiceInfos = function(receipt_number) {
	this.xhr = $.getJSON('/?datatype=json&datasource=invoice_comment&receipt_number='+receipt_number, this.getInvoiceInfosDetails.bind(this));
};
