/*******************************************************************

	Modal Inline Table

*******************************************************************/

function ModalInlineTable($modal) {
    this.$modal = $modal;
    this.$table = $modal.find('.invoices_action_modal__summary');
    this.labels = [
        'Datum',
        'BelegNr',
        'Name',
        'Brutto',
        'Offen',
        'Waehrung'
    ];
}

ModalInlineTable.prototype.clear = function() {
    this.$table.find('tfoot').show();
    this.$table.find('tbody').empty();
};

ModalInlineTable.prototype.init = function() {
    this.$table.find('tfoot').hide();
};

ModalInlineTable.prototype.addData = function(data) {

    for (var k in data){
        if(this.labels.indexOf(k) !== -1) {
            this.$table.find('tbody').append('<tr><th>'+k+'</th><td>'+data[k]+'</td></tr>');
        }
    }
};
