/*******************************************************************

	Modal Invoice Record

*******************************************************************/

function ModalInvoiceUploaded() {
    this.$modal = $('.invoices_upload__modal.invoices_upload__modal--success');
    this.$btnPrimary = this.$modal.find('.modal-footer .btn--action');
    this.$btnSecondary = this.$modal.find('.modal-footer .btn--cancel');
    this.invoicesOK = -1;
    this.invoicesTotal = -1;
    this.client = '';
    this.iban = '';
    this.feedback = new ModalFeedback();
    this.data = [];
    this.url = undefined;
    this.mailer = undefined;
	this.xhr = undefined;
}


ModalInvoiceUploaded.prototype.init = function(invoices) {
    this.data = invoices;

    this.url = this.$modal.data('action');
    this.mailer = this.$modal.data('mailer');
    this.invoicesOK = this.getPaidInvoicesCount();
    this.invoicesTotal = this.getTotalInvoicesCount();
    this.client = this.$modal.data('client');
    this.iban = this.$modal.data('iban');

    this.$btnPrimary.attr('disabled', false);
    this.$btnPrimary.bind('click', $.proxy(this.onPrimary, this));
    this.$btnSecondary.bind('click', $.proxy(this.onSecondary, this));

    this.$modal.find('.invoices_upload__modal__count').text(this.invoicesOK);
    this.$modal.find('.invoices_upload__modal__total').text(this.invoicesTotal);

    this.$modal.modal({
        backdrop: 'static'
    });
};

ModalInvoiceUploaded.prototype.getTotalInvoicesCount = function(invoices) {
    return this.data.length;
};

ModalInvoiceUploaded.prototype.getPaidInvoicesCount = function(invoices) {
    var count = 0;

    for(i = 0; i < this.data.length; i++) {
        if(this.data[i].state == 'paid') {
            count++;
        }
    }

    return count;
};

ModalInvoiceUploaded.prototype.destroy = function() {
    this.$btnPrimary.unbind('click', this.onPrimary);
    this.$btnSecondary.unbind('click', this.onSecondary);
    this.$btnPrimary.attr('disabled', true);
    this.$modal.find('.invoices_upload__modal__count').text('0');
    this.$modal.find('.invoices_upload__modal__total').text('0');
    this.data = [];
    this.client = '';
    this.invoicesOK = -1;
    this.invoicesTotal = -1;
    this.url = undefined;
    this.mailer = undefined;

    this.$modal.modal('hide');
};


ModalInvoiceUploaded.prototype.ajaxStart = function() {

    this.$btnPrimary.append('<i class="loader"></i>');
    this.$btnPrimary.attr('disabled', true);

    var dataToSend = [];
    var i;

    for(i = 0; i < this.data.length; i++) {
        if(this.data[i].state == 'paid') {
            this.data[i].data['Firma_BankIBAN'] = this.iban;
            dataToSend.push(this.data[i].data);
        }
    }

    var dataClone = jQuery.extend(true, {}, dataToSend);

    this.xhr = $.ajax({
        method: 'POST',
        url: this.$modal.data('action'),
        cache: false,
        data: {
            'cmd': 'zahlungCreate',
            'invoices': dataClone
        },
    }).done($.proxy(this.ajaxComplete, this));

    if(this.$modal.find('input[name=transmit_errors]:checked').length > 0) {
        var esrnumberString = '';

        for(i = 0; i < this.data.length; i++) {
            if(this.data[i].state === 'error') {
                    esrnumberString += this.data[i].esrnumber;
            }
        }

        if(esrnumberString !== '') {
            $.ajax({
                method: 'POST',
                url: this.mailer,
                cache: false,
                data: {
                    Kunde: this.client,
                    esrnumbers: esrnumberString
                }
            }).done(function(feedback){
                console.log('esr feedback', feedback);
            });
        }
    }
};

ModalInvoiceUploaded.prototype.ajaxComplete = function(feedback) {

    this.$btnPrimary.find('.loader').remove();
    this.$btnPrimary.attr('disabled', false);

    this.destroy();
    this.feedback.init(feedback);
};


ModalInvoiceUploaded.prototype.onPrimary = function(e) {
    if(!this.$btnPrimary.attr('disabled')) {
        this.ajaxStart();
    }

    e.preventDefault();
};

ModalInvoiceUploaded.prototype.onSecondary = function(e) {
    //Just hide, do not destroy
    //this.destroy();
    this.$modal.modal('hide');

    e.preventDefault();
};
