/*******************************************************************

	Modal Invoice Record

*******************************************************************/

function ModalInvoiceRecord() {
    this.$modal = $('.invoices_action_modal.invoices_action_modal--record');
    this.$btnPrimary = this.$modal.find('.modal-footer .btn--action');
    this.$btnSecondary = this.$modal.find('.modal-footer .btn--cancel');
    this.table = new ModalInlineTable(this.$modal);
    this.feedback = new ModalFeedback();
    this.data = [];
    this.url = undefined;
	this.xhr = undefined;
}


ModalInvoiceRecord.prototype.init = function(url) {
    this.data = [];
    this.url = url;

    this.$btnPrimary.attr('disabled', true);
    this.$btnPrimary.bind('click', $.proxy(this.onPrimary, this));
    this.$btnSecondary.bind('click', $.proxy(this.onSecondary, this));

    this.$modal.modal({
        backdrop: 'static'
    });
    this.loadInitData();
};

ModalInvoiceRecord.prototype.destroy = function() {
    this.$btnPrimary.unbind('click', this.onPrimary);
    this.$btnSecondary.unbind('click', this.onSecondary);
    this.$btnPrimary.attr('disabled', true);
    this.$modal.find('textarea[name=comment]').val('');
    this.table.clear();
    this.data = [];
    this.url = undefined;

    this.$modal.modal('hide');
};

ModalInvoiceRecord.prototype.loadInitData = function() {
    this.table.clear();

    $.getJSON(this.url, $.proxy(function(data) {
        this.table.init();

        this.data = data.data;
        this.table.addData(this.data);
        this.$btnPrimary.attr('disabled', false);
    }, this));
};

ModalInvoiceRecord.prototype.ajaxStart = function() {

    if(this.validate()) {
        this.$btnPrimary.append('<i class="loader"></i>');
        this.$btnPrimary.attr('disabled', true);

        this.data.Betrag = this.$modal.find('#record_invoice-betrag').val();
        this.data.Gutschriftsdatum = this.$modal.find('#record_invoice-date').val();
        this.data.Zahlungsart = this.$modal.find('input[name=record_invoice-transfer-type]:checked').val();
        this.data.Firma_BankIBAN = this.$modal.data('iban');

        var dataClone = jQuery.extend(true, {}, this.data);

        this.xhr = $.ajax({
            method: 'POST',
            url: this.$modal.data('action'),
            cache: false,
            data: {
                'cmd': 'zahlungManuell',
                'invoices': [dataClone]
            },
        }).done($.proxy(this.ajaxComplete, this));
    }
};

ModalInvoiceRecord.prototype.ajaxComplete = function(feedback) {

    this.$btnPrimary.find('.loader').remove();
    this.$btnPrimary.attr('disabled', false);

    this.destroy();
    this.feedback.init(feedback);
};

ModalInvoiceRecord.prototype.validate = function() {
    var isValid = true;

    //Validate Date
    var re = /^\d{4}-\d{1,2}-\d{1,2}$/;

    if(this.$modal.find('#record_invoice-date').val() === '' || this.$modal.find('#record_invoice-date').val().match(re) === false) {
        this.$modal.find('#record_invoice-date').closest('.form-group').addClass('has-error');
        isValid = false;
    } else {
        this.$modal.find('#record_invoice-date').closest('.form-group').removeClass('has-error');
    }

    //Validate Amount
    if(this.$modal.find('#record_invoice-betrag').val() === '' || isNaN(Number(this.$modal.find('#record_invoice-betrag').val())) === true) {
        this.$modal.find('#record_invoice-betrag').closest('.form-group').addClass('has-error');
        isValid = false;
    } else {
        this.$modal.find('#record_invoice-betrag').closest('.form-group').removeClass('has-error');
    }

    //Validate Transfer Type
    if(this.$modal.find('input[name=record_invoice-transfer-type]').val() === '') {
        this.$modal.find('input[name=record_invoice-transfer-type]').closest('.form-group').addClass('has-error');
        isValid = false;
    } else {
        this.$modal.find('input[name=record_invoice-transfer-type]').closest('.form-group').removeClass('has-error');
    }


    return isValid;
};

ModalInvoiceRecord.prototype.onPrimary = function(e) {
    if(!this.$btnPrimary.attr('disabled')) {
        this.ajaxStart();
    }

    e.preventDefault();
};

ModalInvoiceRecord.prototype.onSecondary = function(e) {
    this.destroy();

    e.preventDefault();
};
