/*******************************************************************


	Invoice Class


*******************************************************************/

//Helper Classes
function startCheckingInvoices() {
	invoices = [];

	$('#invoices__upload__table tbody tr').each(function() {
		invoices.push(new Invoice($(this), invoicesLoadedComplete));
	});

	iLoadingIndex = -1;
    iLoadingDone = 0;
	iLoadingAlready = 0;
    iLoadingOK = 0;
    iLoadingError = 0;
	iLoadingTotal = invoices.length;
    tmpLoadingPortCount = iLoadingPortCount;
	$('.invoices__upload__state__info .info__process__number').text("1/"+iLoadingTotal);
	$('.invoices__upload__state__overview__list .process__state__ok .badge').text(0);
	$('.invoices__upload__state__overview__list .process__state__already .badge').text(0);
	$('.invoices__upload__state__overview__list .process__state__error .badge').text(0);
    $('.invoices__upload__state__overview__list .process__state__total .badge').text(iLoadingTotal);

    if(tmpLoadingPortCount > iLoadingTotal) {
        tmpLoadingPortCount = iLoadingTotal;
    }

    for(var i = 0; i < tmpLoadingPortCount; i++) {
        iLoadingIndex++;
        invoices[iLoadingIndex].start();
    }
}

function abortInvoices() {
	for(var i = 0; i < invoices.length; i++) {
			invoices[i].abort();
	}
}

function invoicesLoadedComplete() {
	iLoadingIndex++;
	iLoadingDone++;

	$('#loadingbar').width((100*iLoadingDone/iLoadingTotal)+"%");

	$('.invoices__upload__state__info .info__process__number').text(iLoadingDone+'/'+iLoadingTotal);
	$('.invoices__upload__state__overview__list .process__state__ok .badge').text(iLoadingOK);
	$('.invoices__upload__state__overview__list .process__state__already .badge').text(iLoadingAlready);
	$('.invoices__upload__state__overview__list .process__state__error .badge').text(iLoadingError);
	$('.invoices__upload__state__overview__list .process__state__total .badge').text(iLoadingTotal);

	if(iLoadingIndex < iLoadingTotal) {
		invoices[iLoadingIndex].start();
	}

	if(iLoadingDone == iLoadingTotal) {

		modalUploaded.init(invoices);

		// $('.invoices_upload__modal.invoices_upload__modal--success .invoices_upload__modal__count').text(iLoadingOK);
		// $('.invoices_upload__modal.invoices_upload__modal--success .invoices_upload__modal__total').text(iLoadingTotal);
		// $('.invoices_upload__modal.invoices_upload__modal--success .modal-footer .btn-primary').bind('click', function(e) {
		// 	var dataToSend = [];
		//
		// 	for(var i = 0; i < invoices.length; i++) {
		// 		if(invoices[i].state == 'paid') {
		// 			dataToSend.push(invoices[i].data);
		// 		}
		// 	}
		//
		// 	$('.invoices_upload__modal.invoices_upload__modal--success .modal-footer .btn-primary').append('<i class="loader loader-white"></i>');
		//
		//
		// 	if($('.invoices_upload__modal.invoices_upload__modal--success input[name=transmit_errors]:checked').length > 0) {
		// 		var esrnumberString = '';
		// 		var client = $('.invoices_upload__modal.invoices_upload__modal--success').data('client');
		//
		// 		$('#invoices__upload__table tbody tr').each(function() {
		// 			if($(this).attr('data-State') === 'error') {
		// 				esrnumberString += $(this).attr('data-ESRNumber'); //+'\n';
		// 			}
		// 		});
		//
		//
		//
		// 		if(esrnumberString !== '') {
		// 			$.ajax({
		// 				method: 'POST',
		// 				url: $('.invoices_upload__modal.invoices_upload__modal--success').attr('data-mailer'),
		// 				cache: false,
		// 				data: {
		// 					Kunde: client,
		// 					esrnumbers: esrnumberString
		// 				}
		// 			}).done(function(feedback){
		// 				//console.log('esr feedback', feedback);
		// 			});
		// 		}
		// 	}
		//
		// 	this.xhr = $.ajax({
		// 		method: 'POST',
		// 		url: '/?datatype=post&func=record',
		// 		cache: false,
		// 		data: {
		// 			invoices: dataToSend
		// 		}
		// 	}).done(function(feedback){
		// 		console.log(feedback);
		// 		$('.invoices_upload__modal.invoices_upload__modal--success .modal-footer .btn-primary .loader').remove();
		// 		$('.invoices_upload__modal.invoices_upload__modal--success').modal('hide');
		//
		// 		$('.invoices_upload__modal.invoices_upload__modal--feedback .invoices_upload__modal__feedback').text(feedback.response);
		// 		$('.invoices_upload__modal.invoices_upload__modal--feedback').modal();
		// 	});
		//
		// 	console.log('send', dataToSend.length, dataToSend);
		// });
		//
		// initInvoicesTransmit();
		$('.invoices__upload__state__info--process').hide();
        $('.invoices__upload__state__info--done').show();
	}
}



function initInvoicesTransmit() {
	$('.invoices_upload__modal.invoices_upload__modal--success').modal();
}


//Constructor

function Invoice($row, completeFunc) {
	this.$row = $row;
	this.data = [];
	this.xhr = undefined;
	this.state = 'pending';
	//this.reference_number = $row.attr('data-reference');
	this.invoice_number = $row.attr('data-rechnungsnummer');
	this.address_number = $row.attr('data-adressnummer');
	this.payment_date = $row.attr('data-gutschriftdatum');
	this.esrnumber = $row.attr('data-ESRNumber');
	this.amount = parseFloat($row.attr('data-betrag'));
	this.complete = completeFunc;
	this.changeState('');
}

//Start loading Invoice Details
Invoice.prototype.start = function(data) {
	this.parseData();
	this.getInvoice(this.address_number);
	this.changeState('loading');
};

Invoice.prototype.abort = function() {
	if(this.xhr !== undefined) {
		this.xhr.abort();
	}
};

//Change State in Table
Invoice.prototype.changeState = function(state, txt) {
	this.state = state;

	this.$row.attr('data-State', state);

	switch (state) {
		case 'loading':
			this.$row.find('.status .label').html(i18n('verifying')+' <i class="loader loader-white"></i>').removeClass().addClass('label label-default');
			break;
		case 'paid':
			this.$row.find('.status .label').text(i18n('paid')).removeClass().addClass('label label-success');
			break;
		case 'already':
			this.$row.find('.status .label').text(i18n('paidAlready')).removeClass().addClass('label label-default');
			break;
		case 'error':
			if(txt === undefined) { txt = i18n('error'); }
			this.$row.find('.status .label').text(txt).removeClass().addClass('label label-danger');
			break;
		default:
			this.$row.find('.status .label').text(i18n('waiting')).removeClass().addClass('label label-default');
			break;
	}
};

Invoice.prototype.parseData = function() {
	var dataObj = this.$row.data();
	for(var key in dataObj) {
		this.data[key.capitalize()] = dataObj[key];
	}
};

//Get account number base on address number
Invoice.prototype.getAccountNumber = function(data) {
	this.xhr = $.getJSON('/?datatype=json&datasource=invoice_details&account_number='+data.data.account_number+'&invoice_number='+this.invoice_number+'&invoice_date='+this.payment_date, this.getInvoiceDetails.bind(this));
};

//Get invoice details voer invoice id
Invoice.prototype.getInvoiceDetails = function(data) {
	if(data.data.length > 0) {
		this.data = $.extend({}, this.data, data.data[0]);
		this.$row.find('.name').text(data.data[0].Name.replaceAll('_', ' ').capitalize(true));
		this.$row.find('.invoice_number').text(data.data[0].BelegNr);

		var dataOffen = parseFloat(data.data[0].Offen);

		if(dataOffen === 0) {
			iLoadingAlready++;
			this.changeState('already');
		} else if(dataOffen != this.amount) {
			iLoadingError++;
			this.changeState('error', i18n('amountNoMatch'));
		} else {
			iLoadingOK++;
			this.changeState('paid');
		}
	} else {
		iLoadingError++;
		this.changeState('error', i18n('notFound'));
	}

	this.complete();
};

//Get Invoice over search
Invoice.prototype.getInvoice = function(address_number) {
	this.xhr = $.getJSON('/?datatype=json&datasource=account_number&address_number='+address_number, this.getAccountNumber.bind(this));
};
